<script>
import Accordeon from "@/components/widgets/Accordeon.vue";

export default {
  name: "ItemUserConnected",
  components: { Accordeon },
  props: {
    users: { type: Array, default: () => [] },
    rowInfo: { type: Object },
    component: { type: String, default: "" },
  },
  data() {
    return {
      stateDic: {
        online: "Conectado",
        away: "Ausente",
        offline: "Desconectado",
      },
      dicTitles: {
        IT: "Infraestructura",
        "Q/A": "Q/A gestión de calidad",
        fotografia: "Diseño y edición",
      },
      newUsers: [],
      usersOffline: [],
      isOpen: false,
      isOpenOffline: false,
    };
  },
  computed: {
    titleName() {
      const title = this.rowInfo?.title || "";
      return this.dicTitles[title] || title;
    },
    totalSpark() {
      return this.users.filter(({ getBy }) => getBy === "spark").length;
    },
    usersfiltered() {
      const users = {
        online: [],
        offline: [],
        spark: [],
      };

      this.users.forEach((user) => {
        const { isOnline, getBy } = user;
        const groupUser = isOnline ? "online" : "offline";

        if (getBy === "spark") {
          users.spark.push(user);
        }

        users[groupUser].push(user);
      });
      return users;
    },
    totalUsers() {
      const { online, offline } = this.usersfiltered;
      return online.length + offline.length;
    },
  },
  methods: {
    async openUsers() {
      const users = this.usersfiltered.online;
      this.isOpen = !this.isOpen;
      let time = 15;
      this.isOpen ? (this.newUsers = []) : (time = 8);

      for (let i = 0; i < users.length; i++) {
        const user = await new Promise((resolve, reject) =>
          setTimeout(resolve, time, users[i])
        );
        this.isOpen ? this.newUsers.push(user) : this.newUsers.pop();
      }
    },
    async openOfflineUsers() {
      const users = this.usersfiltered.offline;
      this.isOpenOffline = !this.isOpenOffline;
      let time = 15;
      this.isOpenOffline ? (this.usersOffline = []) : (time = 8);

      for (let i = 0; i < users.length; i++) {
        const user = await new Promise((resolve, reject) =>
          setTimeout(resolve, time, users[i])
        );
        this.isOpenOffline ? this.usersOffline.push(user) : this.usersOffline.pop();
      }
    },
    getState(user) {
      const { isActive, isOnline } = user;
      return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    },
    async openOpenOrClose() {
      const promises = [this.openUsers()];

      if (this.isOpenOffline) {
        promises.push(this.openOfflineUsers());
      }

      await Promise.all(promises);
    },
  },
};
</script>

<template>
  <section :class="`itemUserConnected itemUserConnected--${component}`">
    <div class="itemUserConnected__header" @click="openOpenOrClose">
      <h5 class="itemUserConnected__title">{{ titleName }}</h5>
      <div class="itemUserConnected__contentBadge">
        <div class="tool-tip">
          <div class="itemUserConnected__badge itemUserConnected__badge--spark">
            {{ usersfiltered.spark.length }}
          </div>
          <span class="tooltiptext tool-auto">Spark</span>
        </div>
        <div class="tool-tip">
          <div class="itemUserConnected__badge itemUserConnected__badge--online">
            {{ usersfiltered.online.length - usersfiltered.spark.length }}
          </div>
          <span class="tooltiptext tool-auto">Conectados</span>
        </div>
        <div class="tool-tip">
          <div class="itemUserConnected__badge itemUserConnected__badge--offline">
            {{ usersfiltered.offline.length }}
          </div>
          <span class="tooltiptext tool-auto">Desconectados</span>
        </div>
        <div class="tool-tip">
          <div class="itemUserConnected__badge itemUserConnected__badge--total">
            {{ totalUsers }}
          </div>
          <span class="tooltiptext tool-auto">Total</span>
        </div>
      </div>
    </div>
    <template>
      <div
        class="itemUserConnected__wrapper fade-in"
        v-for="(user, idx) in newUsers"
        :key="`${user.user}_${idx}`"
        :set="(state = getState(user))"
      >
        <div class="itemUserConnected__contentData">
          <div class="itemUserConnected__avatarContainer" :stateAvatar="state">
            <Avatar class="itemUserConnected__avatar" :user="user" />
            <span class="itemUserConnected__statusCircle" :stateAvatar="state"></span>
          </div>
          <div class="itemUserConnected__nameContainer">
            <span class="itemUserConnected__name">{{ user.user }}</span>
            <span class="itemUserConnected__stateUser">{{ stateDic[state] }}</span>
          </div>
        </div>
        <img
          class="itemUserConnected__imgSpark"
          src="../../../assets/img/logo_spark.gif"
          alt="spark"
          v-if="user.getBy === 'spark'"
        />
      </div>
      <div
        class="itemUserConnected__wrapper fade-in"
        v-for="(user, idx) in usersOffline"
        :key="`${user.user}_${idx}`"
      >
        <div class="itemUserConnected__contentData">
          <div class="itemUserConnected__avatarContainer" :stateAvatar="'offline'">
            <Avatar class="itemUserConnected__avatar" :user="user" />
            <span class="itemUserConnected__statusCircle" :stateAvatar="'offline'"></span>
          </div>
          <div class="itemUserConnected__nameContainer">
            <span class="itemUserConnected__name">{{ user.user }}</span>
            <span class="itemUserConnected__stateUser">{{ stateDic["offline"] }}</span>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="
        newUsers.length === usersfiltered.online.length && usersfiltered.offline.length
      "
    >
      <div class="" @click="openOfflineUsers">
        <span class="itemUserConnected__offlineUsers">{{
          `${usersOffline.length ? "Ocultar" : "Ver"} desconectados`
        }}</span>
      </div>
    </template>
  </section>
</template>

<style lang="scss">
.itemUserConnected {
  padding: $mpadding;
  border-radius: 4px;
  background-color: $white;
  box-shadow: $dshadow;
  &--models {
    border: 1px solid $alto;
    border-radius: 10px;
    background-color: transparent;
    box-shadow: none;
  }

  .expand-enter-active,
  .expand-leave-active {
    transition-property: opacity, height;
  }
  .expand-enter,
  .expand-leave-to {
    opacity: 0;
  }
  .tooltiptext {
    font-size: 12px;
  }
  &__header {
    @include Row(flex-start, center);
    cursor: pointer;
  }
  &__title {
    font-size: 16px;
    flex-grow: 10;
    font-weight: 400;
    color: $black;
  }
  &__contentBadge {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32px, 1fr));
    max-width: 217px;
    gap: 7px;
  }
  &__badge {
    @include Row();
    height: 22px;
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 600;
    color: $white;
    border-radius: 15px;
    &--spark {
      color: #ff8000bc;
      background-color: #fd9b391c;
    }
    &--offline {
      color: $chicago;
      background-color: #5b59571c;
    }
    &--online {
      color: $la_palma;
      background-color: #1f9e0c1f;
    }
    &--total {
      color: $primary-color;
      background-color: #bd090914;
    }
  }
  &__wrapper {
    @include Row(space-between);
    padding: 5px $mpadding/2;
    height: inherit;
    margin: $mpadding/3 0;
    border-radius: 27px;
    background-color: $backgroundCards;
  }
  &__contentData {
    @include Row(flex-start);
  }
  &__avatarContainer {
    border-radius: 50%;
    border: 2px solid $alto;
    position: relative;
    &[stateAvatar="online"] {
      border-color: $la_palma;
    }
    &[stateAvatar="away"] {
      border-color: $tree_Poppy;
    }
  }
  &__statusCircle {
    width: 9px;
    height: 9px;
    position: absolute;
    right: 1px;
    bottom: 1px;
    border-radius: 50%;
    border: 1px solid $white;
    background-color: $alto;
    &[stateAvatar="online"] {
      background-color: $la_palma;
    }
    &[stateAvatar="away"] {
      background-color: $tree_Poppy;
    }
  }
  &__avatar {
    width: 33px;
    height: 33px;
    flex-shrink: 0;
    border: 1px solid $white;
  }
  &__nameContainer {
    @include Column(center, flex-start);
    margin: 0 $mpadding/1.5;
  }
  &__name,
  &__stateUser {
    text-transform: capitalize;
    line-height: 1;
  }
  &__stateUser {
    font-size: 14px;
    color: $gray-color;
  }
  &__imgSpark {
    height: 16px;
    margin-right: 8px;
  }
  .accordeon__ico {
    font-size: 11px;
  }
  &__offlineUsers {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
  @include tabletWidth() {
    &__title {
      font-size: 19px;
    }
    &__state {
      font-size: 15px;
    }
    &__nameContainer {
      @include Row(flex-start);
    }
    &__stateUser {
      margin: 0 $mpadding * 1;
      position: relative;
      &::after {
        content: "·";
        font-weight: 700;
        position: absolute;
        top: 0;
        left: -8px;
      }
    }
    &__contentBadge {
      grid-template-columns: repeat(auto-fit, minmax(46px, 1fr));
    }
    &__badge {
      height: 25px;
      padding: 0 15px;
      font-size: 16px;
    }
    &__offlineUsers {
      font-size: 16px;
    }
  }
}
</style>
