<script>
export default {
  props: {
    actionOpen: { type: Function, default: () => {} },
    time: { type: Number, default: 200 },
    hasFooter: { type: Boolean, default: false },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    getNameIco() {
      return this.isOpen ? "chevronUp" : "chevronDown";
    },
  },
  methods: {
    afterEnter(element) {
      element.style.height = `auto`;
    },
    enter(element) {
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.position = `absolute`;
      element.style.visibility = `hidden`;
      element.style.height = `auto`;
      const { height } = getComputedStyle(element);
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    leave(element) {
      const { height } = getComputedStyle(element);
      element.style.height = height;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>
<template>
  <div class="accordeon">
    <div class="accordeon__row" @click="isOpen = !isOpen">
      <slot name="row"></slot>
      <iconic class="accordeon__ico" :name="getNameIco" />
    </div>
    <div class="accordeon__body">
      <transition-group name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
        <slot name="body" v-if="isOpen"></slot>
        <div class="accordeon__footer" v-if="hasFooter" @click="isOpen = false">
          <slot name="footer"></slot>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
<style lang="scss">
.accordeon {
  &__row {
    @include Row(flex-start, center);
    cursor: pointer;
  }
}
.expand-enter-active,
.expand-leave-active {
  transition: height 0.5s ease-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
